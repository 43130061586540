import styled from 'styled-components'
import { marginProps, paddingProps, borderProps } from '../utils/spacing.js'

const Flex = styled.div`
    display: flex;
    ${marginProps};
    ${paddingProps};
    ${borderProps};
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    flex-flow: ${(props) => (props.direction ? props.direction : 'row')};
    justify-content: ${(props) => (props.justify ? props.justify : 'center')};
    align-items: ${(props) => (props.align ? props.align : 'center')};
    background: ${(props) => (props.background ? props.background : 'none')};
    flex: ${(props) => (props.flex ? props.flex : '1 1 auto')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
    cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};
`

export default Flex
